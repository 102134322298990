<template>
  <div class="replay-manager-schedule">
    <div class="replay-manager-schedule__header">
      Start replay in
    </div>
    <div class="replay-manager-schedule__scroller">
      <div class="replay-manager-schedule__content">
        <div
          v-for="(offset, offsetIndex) in schedule"
          :key="offsetIndex"
          class="replay-manager-schedule__offset-input"
        >
          <div class="replay-manager-schedule__offset-input-group">
            <NumberInput
              class="replay-manager-schedule__offset-input-field"
              :model-value="calculateOffsetHours(offset)"
              @update:model-value="value => updateOffsetHours(offsetIndex, +value)"
              :readonly="disabled"
              :number-of-decimals="0"
            />
            hours
            <NumberInput
              class="replay-manager-schedule__offset-input-field"
              :model-value="calculateOffsetMinutes(offset)"
              @update:model-value="value => updateOffsetMinutes(offsetIndex, +value)"
              :readonly="disabled"
              :number-of-decimals="0"
            />
            minutes
          </div>
          <div
            v-if="offsetIndex"
            class="replay-manager-schedule__offset-input-action"
          >
            <Button
              variant="tertiary"
              :disabled="disabled"
              icon="x"
              icon-only
              @click="removeOffsetFromSchedule(offsetIndex)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="replay-manager-schedule__footer">
      <Button
        icon="plus"
        :disabled="disabled"
        @click="addOffsetToSchedule"
      >
        Add more
      </Button>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import Button from '@/components/common/Button';
import NumberInput from '@/components/common/NumberInput';

const emitTypes = {
  UPDATE_SCHEDULE: 'update:schedule',
};

const calculateOffsetHours = (offset) => Math.floor(offset / 60);
const calculateOffsetMinutes = (offset) => offset % 60;
const convertHoursToMinutes = (hours) => hours * 60;

export default {
  components: {
    Button,
    NumberInput,
  },
  props: {
    schedule: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    [emitTypes.UPDATE_SCHEDULE]: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const addOffsetToSchedule = () => {
      const updatedSchedule = [...props.schedule, 0];
      emit(emitTypes.UPDATE_SCHEDULE, updatedSchedule);
    };
    const removeOffsetFromSchedule = (index) => {
      const updatedSchedule = cloneDeep(props.schedule);
      updatedSchedule.splice(index, 1);
      emit(emitTypes.UPDATE_SCHEDULE, updatedSchedule);
    };
    const updateOffsetHours = ((index, offsetHours) => {
      const updatedSchedule = cloneDeep(props.schedule);
      const currentOffset = updatedSchedule[index];
      const hoursFromOffsetInMinutes = convertHoursToMinutes(calculateOffsetHours(currentOffset));
      const remainingCurrentOffset = currentOffset - hoursFromOffsetInMinutes;
      const offsetHoursInMinutes = offsetHours * 60;
      updatedSchedule[index] = remainingCurrentOffset + offsetHoursInMinutes;
      emit(emitTypes.UPDATE_SCHEDULE, updatedSchedule);
    });
    const updateOffsetMinutes = ((index, offsetMinutes) => {
      const updatedSchedule = cloneDeep(props.schedule);
      const currentOffset = updatedSchedule[index];
      const minutesFromCurrentOffset = calculateOffsetMinutes(currentOffset);
      const remainingCurrentOffset = currentOffset - minutesFromCurrentOffset;
      updatedSchedule[index] = remainingCurrentOffset + offsetMinutes;
      emit(emitTypes.UPDATE_SCHEDULE, updatedSchedule);
    });

    return {
      calculateOffsetHours,
      calculateOffsetMinutes,
      addOffsetToSchedule,
      removeOffsetFromSchedule,
      updateOffsetHours,
      updateOffsetMinutes,
    };
  },
};
</script>

<style lang="scss">
.replay-manager-schedule {
  padding: 1rem;
  border-top: 1px solid #F0F0F0;
  max-height: 300px;
  overflow-y: auto;
  stroke: #000;

  .replay-manager-schedule__header {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #A9A9A9;
    padding-bottom: 0.5rem;
  }

  .replay-manager-schedule__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .replay-manager-schedule__offset-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .replay-manager-schedule__offset-input-group {
    display: grid;
    align-items: center;
    grid-template-rows: 2.0625rem;
    grid-template-columns: 3rem auto 3rem auto;
    column-gap: 0.5rem;
  }

  .replay-manager-schedule__footer {
    padding-top: 0.5rem;
  }
}
</style>
